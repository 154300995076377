import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "lCommon pBanners" }
const _hoisted_2 = { class: "banners" }
const _hoisted_3 = { class: "banners" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_banner_square = _resolveComponent("as-banner-square")!
  const _component_as_empty = _resolveComponent("as-empty")!
  const _component_as_container = _resolveComponent("as-container")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, { target: "mobile-tablet" }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_mobile, {
          title: _ctx.$t('YouMayLike'),
          "prev-page": _ctx.prevPage,
          "onMenu:toggle": _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpenMenu = $event))
        }, null, 8, ["title", "prev-page"]),
        _withDirectives(_createVNode(_component_as_container, null, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersCatalog, (banner) => {
                return (_openBlock(), _createBlock(_component_as_banner_square, {
                  key: banner.id,
                  banner: banner,
                  position: "banners",
                  placement: _ctx.bannersPlacements.catalog,
                  platform: _ctx.bannersPlatforms.mobile
                }, null, 8, ["banner", "placement", "platform"]))
              }), 128))
            ]),
            (_ctx.bannersCatalog.length === 0)
              ? (_openBlock(), _createBlock(_component_as_empty, { key: 0 }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 512), [
          [_vShow, !_ctx.isOpenMenu]
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_mq_responsive, {
      class: "lCommon bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, {
              title: _ctx.$t('YouMayLike')
            }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, null, {
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"])
          ]),
          content: _withCtx(() => [
            _createVNode(_component_as_row, { class: "-inner" }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersCatalog, (banner) => {
                    return (_openBlock(), _createBlock(_component_as_banner_square, {
                      key: banner.id,
                      banner: banner,
                      position: "banners",
                      placement: _ctx.bannersPlacements.catalog,
                      platform: _ctx.bannersPlatforms.desktop
                    }, null, 8, ["banner", "placement", "platform"]))
                  }), 128))
                ]),
                (_ctx.bannersCatalog.length === 0)
                  ? (_openBlock(), _createBlock(_component_as_empty, { key: 0 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 1
    })
  ]))
}