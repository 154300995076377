
import { ref, computed, defineComponent } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'
import AsMenu from '@web/components/AsMenu.vue'
import AsRow from '@web/components/AsRow.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsBannerSquare from '@web/components/AsBannerSquare.vue'
import AsContainer from '@web/components/AsContainer.vue'
import AsEmpty from '@web/components/AsEmpty.vue'
import { useStore } from '@web/store'
import { useAllCatalogBanners } from '@web/composition/useBanners'
import PAGE_NAME from '@web/consts/PAGE_NAME'

export default defineComponent({
  name: 'Banners',
  components: {
    MqResponsive,
    AsHeaderMobile,
    AsMenu,
    AsRow,
    AsHeaderMain,
    AsFooter,
    AsSpacer,
    AsBreadcrumb,
    AsBannerSquare,
    AsContainer,
    AsEmpty
  },
  setup () {
    const store = useStore()
    const isOpenMenu = ref(false)
    const prevPage = { name: PAGE_NAME.HOME }

    const menu = computed(() => store.getters['menu/general'])

    return {
      isOpenMenu,
      menu,
      prevPage,
      ...useAllCatalogBanners()
    }
  }
})
